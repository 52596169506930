import Router from 'next/router'

import styled from 'styled-components'

import { Column, Row } from 'components/v2/Grid'
import { Button } from 'components/v2/Button'
import { Text } from 'components/v2/Text'
import { Image } from 'components/v2/Image'

export const DownloadAppSection = () => {
  return (
    <Row
      id='download-app'
      as='section'
      width='100%'
      bg='primary'
      justifyContent='center'
      pt={['40px', '40px', '52px']}
      px='16px'
      mt='64px'
    >
      <Container flexDirection={['column', 'column', 'row']}>
        <Column>
          <Row gridGap='40px' alignItems='center'>
            <Image
              src={
                process.env.NODE_ENV === 'production'
                  ? '/static/images/app/qrcode-prod-download-app.svg'
                  : '/static/images/app/qrcode-dev-download-app.svg'
              }
              width='184px'
              bg='white'
              borderRadius='10px'
              display={['none', 'none', 'block']}
            />
            <Column gridGap='16px' textAlign={['center', 'center', 'left']}>
              <Text fontSize='22px' color='white' fontWeight='700'>
                Baixe o app da Vittude
              </Text>
              <Text color='white'>
                Através do aplicativo da Vittude você consegue gerenciar suas consultas, realizar
                agendamentos e acompanhar seu humor e sono ao longo da semana.
              </Text>
              <Button
                variant='tertiary'
                color='#1C1B1B'
                height='56px'
                fontSize='14px'
                width='100%'
                fontWeight={600}
                borderRadius='10px'
                alignItems='center'
                justifyContent='center'
                gridGap='8px'
                display={['flex', 'flex', 'none']}
                mt='8px'
                onClick={() => Router.push('/app-redirect')}
              >
                Instalar aplicativo
                <Image src='/static/images/icon-download-dark.svg' width='24px' height='24px' />
              </Button>
            </Column>
          </Row>
        </Column>
        <Image src='/static/images/app/prints/welcome-home.svg' />
      </Container>
    </Row>
  )
}

const Container = styled(Row)`
  max-width: 1200px;
  gap: 64px;
  align-items: center;
`

import React from 'react'
import { Row, Column } from '../../v2/Grid'
import { Text } from '../../v2/Text'
import { LinkComponent } from '../../v2/Link'
import LazyImage from '../LazyImage'

export const FooterAlertHome = props => (
  <section id='cvv-footer-new'>
    <Row flexWrap='wrap' minWidth='100%' background='#E1DCD3 0% 0% no-repeat padding-box'>
      <Row
        maxWidth='1200px'
        margin='0 auto'
        alignItems={['flex-start', 'center']}
        px='16px'
        py='56px'
        flexWrap='wrap'
      >
        <Column sm='12' md='3' lg='3'>
          <Text color='#232936' fontSize='12px' mb='2' textAlign='left'>
            Responsável Técnico
          </Text>
          <Text color='#232936' fontWeight='600' textAlign='left'>
            Luckas Reis Pedroso dos Santos
          </Text>
          <Text color='#232936' fontSize='14px' textAlign='left' pb={['30px', '0px']}>
            CRP (06/141723)
          </Text>
        </Column>
        <Column
          sm='12'
          md='1'
          lg='1'
          alignItems='center'
          justifyContent={['center', 'flex-start']}
          py='10px'
        >
          <LazyImage
            title='Ícone de Alerta'
            alt='Ícone de Alerta'
            src={'/static/images/alerta-blank.svg'}
            width={38}
            height={38}
          />
        </Column>
        <Column sm='12' md='8' lg='8' alignItems={['flex-start', 'center']} pl={['0', '16px', '0']}>
          <Text color='#232936' lineHeight='24px' textAlign='left'>
            Atenção: Este site não oferece tratamento ou aconselhamento imediato para pessoas em
            crise suicida. Em caso de crise, ligue para{' '}
            <LinkComponent label='188 (CVV)' color='#232936' href='tel:+55141' hover='#FF7708' /> ou
            acesse o site{' '}
            <LinkComponent
              label='www.cvv.org.br'
              color='#232936'
              target='_blank'
              hover='#FF7708'
              rel='noreferrer'
            />
            . Em caso de emergência, procure atendimento em um hospital mais próximo.
          </Text>
        </Column>
      </Row>
    </Row>
  </section>
)
